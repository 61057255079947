/* eslint-disable no-console */
import {createElement} from "react";
import {render} from "react-dom";
import SuspenseLoader from "~/components/SuspenseLoader";
import "./util/siteroot";

async function initComponent() {
	const {default:launchers} = await import("./launchers");
	const context = await launchers.initContext();
	try {
		await launchers.initComponent(context);
	} catch(e) {
		console.error(e);
	}
}

const rootElement = document.getElementById("react-root");
const loader = createElement(SuspenseLoader);
render(loader, rootElement);
initComponent();