import React from "react";
import Loader from "~/components/Loader";

export default function SuspenseLoader({bg = false}: {bg?: boolean}) {
	const style = React.useMemo<React.CSSProperties>(() => ({
		position:"absolute",
		top:0,
		left:0,
		right:0,
		bottom:0,
		display:"flex",
		alignItems:"center",
		justifyContent:"center",
		backgroundColor:bg ? "#0006" : undefined,
	}), [bg]);

	return (
		<div style={style}>
			<Loader size={128} thickness={16} variant={bg ? "light" : "default"} />
		</div>
	);
}