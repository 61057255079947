/* global __webpack_public_path__: true */
/* eslint camelcase: ["error", {allow: ["__webpack_public_path__"]}] */
// noinspection JSUnresolvedVariable
let siteRoot = __webpack_public_path__ || './';

const siteRootEl = document.getElementById('site-root');
if (siteRootEl) {
	// noinspection JSUnresolvedVariable
	__webpack_public_path__ = siteRoot = siteRootEl.href;
}

function getSiteRoot() {
	return siteRoot;
}

export function redirectTo(view) {
	window.location.href = `${getSiteRoot()}view/${view}`;
}

export default getSiteRoot;