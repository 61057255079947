import React from "react";
import cn from "classnames";
import styles from "./style.scss";

type Props = React.HTMLProps<HTMLDivElement> & {
	variant?: "default" | "light";
	size?: number;
	thickness?: number;
	inline?: boolean;
};

const Loader = ({variant = "default", inline, ...props}: Props) => (
	<div
		{...props}
		style={{width:props.size, height:props.size, borderWidth:props.thickness, ...props.style}}
		className={cn(styles.loader, inline && styles.inline, styles[variant], props.className)}
	/>
);

export default Loader;